import { MinusOutlined, PlusOutlined, ShoppingOutlined } from "@ant-design/icons";
import { Button, message, Modal, Space, Spin, Tag, Tooltip } from "antd";
import * as React from "react";
import { getFabricStockDetails } from "../../utils/productsListingPageQueries";
import { useDispatch } from "react-redux";
import { cartActions } from "../../redux-utils/reducers/cartReducer";

interface AddToCartProps {
    productId: number;
}

interface FabricStockDetails {
    id: number;
    product_id: number;
    qty: number;
}

const AddToCart: React.FC<AddToCartProps> = ({ productId }) => {
    const [loading, setLoading] = React.useState(false);
    const [fabricStockDetails, setFabricStockDetails] = React.useState<FabricStockDetails>();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [qty, setQty] = React.useState(1);
    const dispatch = useDispatch();

    const handleOk = () => {
        dispatch(cartActions.addToCart({ productId, stockId: fabricStockDetails?.id, qty }));
        setIsModalOpen(false);
        message.success({ 
            content: "Added product to cart",
            style: {
                marginTop: '90px'
            } 
        });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const addToCartClick = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
        e?.stopPropagation();
        setIsModalOpen(true);
    }, []);

    React.useEffect(() => {
        if (isModalOpen && productId) {
            setLoading(true);
            getFabricStockDetails(productId)
            .then(res => {
                const stock_details: FabricStockDetails[] = res.data;
                setFabricStockDetails(stock_details?.[0]);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            })
        }
    }, [isModalOpen, productId]);

    const reduceQty = React.useCallback(
        () => {
            setQty((q) => q - 1);
            // window.woopra.track("details_page:reduce_qty:click", {
            // url: window.location.pathname,
            // title: document.title,
            // stockId,
            // });
        },
        []
    );

    const increaseQty = React.useCallback(
        () => {
            setQty((q) => q + 1);
            // window.woopra.track("details_page:increase_qty:click", {
            // url: window.location.pathname,
            // title: document.title,
            // stockId,
            // });
        },
        []
    );

    const isOutOfStock = !!fabricStockDetails ? fabricStockDetails?.qty < 1 : true;
    const maxQty = fabricStockDetails?.qty ?? 0;

    return (
        <>
            <Button
                type="primary"
                shape="round"
                size="large"
                onClick={addToCartClick}
                className="add-to-cart-btn"
            >
                <ShoppingOutlined />
                Add to cart
            </Button>
            <Modal title="Quantity" visible={isModalOpen} okButtonProps={{ disabled: isOutOfStock }} okText="Add to cart" onOk={handleOk} onCancel={handleCancel}>
                {loading && <div style={{ textAlign: "center" }}>
                    <Spin />
                </div>}
                {!loading && !fabricStockDetails && <p>Some error occured. Please try again.</p>}
                {!loading && !!fabricStockDetails && (
                    <div>
                        {isOutOfStock && <Tag color="error">OUT OF STOCK</Tag>}
                        {!isOutOfStock && <div className="quantity-update">
                            <p>
                                <strong>Quantity (in metre)</strong>
                            </p>
                            <Space>
                                {qty > 0 && (
                                <Tooltip title="Remove">
                                    <Button
                                    size="small"
                                    type="default"
                                    shape="circle"
                                    icon={<MinusOutlined />}
                                    disabled={qty === 1}
                                    onClick={reduceQty}
                                    />
                                </Tooltip>
                                )}
                                <span>{qty}</span>
                                <Tooltip title="Add">
                                <Button
                                    size="small"
                                    type="default"
                                    shape="circle"
                                    icon={<PlusOutlined />}
                                    disabled={qty === maxQty}
                                    onClick={increaseQty}
                                />
                                </Tooltip>
                            </Space>
                        </div>}
                    </div>
                )}
            </Modal>
        </>
    );
};

export default React.memo(AddToCart);