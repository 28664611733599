import Axios from "axios";

export const wholesaleQuery = (
    fname: string,
    lname: string,
    email: string,
    mobile: string,
    message: string,
  ) => {
    const params = new URLSearchParams({
      fname,
      lname,
      email,
      mobile,
      message,
    });
  
    return Axios.post(
      "https://e3fabrica.com/backend/wholesale.php",
      params
    );
  };