import { Typography } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";
import * as React from "react";

const ContactUs = () => {
  return (
    <Typography className="medium-font">
      <Title level={3}>Contact Us</Title>
      <Paragraph>
        For questions related to online orders, please send us an email at
        e3fabrica@gmail.com. Kindly include your order number for faster
        assistance.
      </Paragraph>
      <Paragraph>
        We aim to respond to each email within 1-2 business day however during
        new product releases it may take us a little longer. We appreciate your
        patience. Please note that our current hours of service are between
        9:00am – 5:00pm IST Monday to Friday.
      </Paragraph>
      <Paragraph>
        You can also reach us by Whatsapp (+91-7620830917) between 9am – 5pm IST
        Monday to Friday.
      </Paragraph>
    </Typography>
  );
};

export default ContactUs;
