import React from "react";
import { Link } from "react-router-dom";

const AppLogo = () => {
  const appLogoClick = React.useCallback(() => {
    // window.woopra.track("app_logo:click", {
    //   url: window.location.pathname,
    //   title: document.title,
    // });
  }, []);

  return (
    <Link to={"/"} onClick={appLogoClick}>
      <div className="app-logo-container">
        <img
          src="https://e3fabrica.com/images/logos/e3_logo_1.png"
          alt=""
          className="logo"
        />
      </div>
    </Link>
  );
};

export default React.memo(AppLogo);
