import { Typography } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";

const Shipping = () => {
  return (
    <Typography className="medium-font">
      <Title level={3}>Shipping</Title>
      <Paragraph>
        <ul>
          <li>We ship across India. For international deliveries, please contact us.</li>
          <li>Order are shipped within 24-48 hours of being confirmed.</li>
          <li>All orders are shipped by Blue dart. Shipment tracking details will be mailed to you on the registered email ID.</li>
          <li>It takes apprixmately 3-7  bussiness days  to deliver your product. Some times delivery may take longer due to bad weather, flight/ transportation delays, political disruptions and / or other unforeseen circumstances.</li>
        </ul>
      </Paragraph>
      <Title level={3}>Return policy</Title>
      <Paragraph>
          Our fabric are put through a rigorous 30- step quality check throughout the entire production process. 
          From the lining of the threads to the beading of the embroidery every thing is looked into with a keen eye and cleared for packing and shipping. 
          Due to these strict measures, our production quality is unparalleled. Owing to the nature of the trade, once a fabric has been cut out and delivered it cannot be returned to us as it's been cut per your individual requirement and order quantity, which varies from order to order.
      </Paragraph>
    </Typography>
  );
};

export default Shipping;
