import { Button, Form, Input, InputNumber, Result } from "antd";
import * as React from "react";
import { wholesaleQuery } from "../../utils/wholesalePageQueries";
import PageLoader from "../Loaders/PageLoader";

enum StatusEnum {
    SUCCESS = "success",
    FAILED = "failed" 
}

interface WholesaleQueryStatus {
    status: StatusEnum;
}

const Wholesale: React.FC<{}> = () => {
    const [loading, setLoading] = React.useState(false);
    const [status, setStatus] = React.useState<StatusEnum>();
    const [form] = Form.useForm();

    const onFinishForm = React.useCallback((formValues: any) => {
        const fname: string = formValues?.first_name ?? "";
        const lname: string = formValues?.last_name ?? "";
        const email: string = formValues?.email_id ?? "";
        const mobile: string = formValues?.phone_number ?? "";
        const message: string = formValues?.message ?? "";

        setLoading(true);
        wholesaleQuery(fname, lname, email, mobile, message)
        .then((res) => {
            const statusResp: WholesaleQueryStatus = res.data;
            setStatus(statusResp?.status);
            setLoading(false);
        })
        .catch((err) => console.log(err));
    }, []);

    return (
        <div className="wholesale-container medium-font">
            <div className="description-container">
                <h1 className="heading">WHOLESALE</h1>
                <p>
                    For Wholesale quantities E3 supplies high quality affordable fabrics to manufacturer designers and retailer. Offering a wide range materials, including natural fibres, syntehtics and prints.
                </p>
            </div>
            {loading && <PageLoader />}
            {!loading && !!status && (
                <Result
                    status={status === StatusEnum.SUCCESS ? "success" : "error"}
                    title={status === StatusEnum.SUCCESS ? "Wholesale Query Recieved" : "A problem occurred. Please try again later"}
                />
            )}
            {!loading && !status && <div className="wholesale-form">
                <Form
                    form={form}
                    name="contact-info-form"
                    onFinish={onFinishForm}
                    layout={"vertical"}
                    autoComplete="off"
                >
                    <Form.Item
                        name="first_name"
                        rules={[
                        {
                            required: true,
                            message: "Enter your first name",
                            type: "string",
                        },
                        ]}
                    >
                        <Input
                            type="text"
                            placeholder="First Name"
                        />
                    </Form.Item>
                    <Form.Item
                        name="last_name"
                        rules={[
                        {
                            required: true,
                            message: "Enter your last name",
                            type: "string",
                        },
                        ]}
                    >
                        <Input placeholder="Last Name" />
                    </Form.Item>
                    <Form.Item
                        name="email_id"
                        rules={[
                        {
                            required: true,
                            message: "Enter your email id",
                            pattern: new RegExp(
                                //eslint-disable-next-line
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            ),
                        },
                        ]}
                    >
                        <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        name="phone_number"
                        rules={[
                            {
                                required: true,
                                message: "Enter your mobile number",
                                pattern: new RegExp(/^[6-9]\d{9}$/gi),
                            },
                        ]}
                    >
                        <InputNumber
                            placeholder="Mobile"
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="message"
                        rules={[
                            {
                                required: true,
                                message: "Please enter a note",
                                type: "string",
                            },
                        ]}
                    >
                        <Input.TextArea
                            placeholder="Message"
                            rows={10}
                        />
                    </Form.Item>
                    <Form.Item>
                        <div className="submit-btn-container">
                            <Button size="large" shape="round" className="medium-font" type="primary" htmlType="submit">Submit</Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>}
        </div>
    );
};

export default Wholesale;