import {
  Button,
  Col,
  Collapse,
  Image,
  message,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Tag,
  Tooltip,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getFabricsList,
  getFabricsListImages,
} from "../../utils/productDetailsPageQueries";
import { CaretRightOutlined, MinusOutlined, PlusOutlined, ShoppingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../redux-utils/reducers/cartReducer";
import PageLoader from "../Loaders/PageLoader";
import { AppState } from "../../redux-utils/store";
// import SizeChartModal from "./SizeChartModal";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as ReactCarousel } from 'react-responsive-carousel';

export interface ProductDetail {
  id: number;
  label: string;
  description: string;
  price: number;
  image_url: string;
  category_id: number;
  width: number;
  item_usage: string;
  work: string;
  fabric: string;
  fabric_code: string;
  washing_instruction: string;
}

interface FabricImage {
  id: number;
  product_id: number;
  stock_id: number;
  image_url: string;
}

interface FabricDetails {
  // fabric details
  id: number;
  label: string;
  description: string;
  price: number;
  image_url: string;
  category_id: number;
  width: number;
  item_usage: string;
  work: string;
  fabric: string;
  fabric_code: string;
  washing_instruction: string;
  // stock details
  stock_id: number;
  qty: number;
}

const ProductDetails = () => {
  const [fabricImages, setFabricImages] = useState<FabricImage[]>([]);
  const [fabricsList, setFabricsList] = useState<FabricDetails[]>([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const productId = params?.["id"] ? parseInt(params?.["id"]) : 0;
  const [selectedProductId, setSelectedProductId] = useState(productId);
  const selectedFabricDetails = fabricsList?.find(fabric => fabric?.id === selectedProductId);
  const selectFabricImages = fabricImages?.filter(image => image?.product_id === selectedProductId);
  const itemExistsInCart = useSelector((state: AppState) =>
    state.cart?.items?.some(
      (item) => item?.productId === selectedProductId && item?.stockId === selectedFabricDetails?.stock_id
    )
  );
  const isFabricOutOfStock = !!selectedFabricDetails ? selectedFabricDetails?.qty < 1 : true;
  const [qty, setQty] = useState(1);
  const maxQty = selectedFabricDetails?.qty ?? 0;
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {  
    // fabric options code
    getFabricsListImages(productId)
      .then((res) => {
        const images: FabricImage[] = res.data;
        setFabricImages(images);
      })
      .catch((err) => {
        console.log("here", err);
      });

    getFabricsList(productId)
      .then((res) => {
        const fabricDetailsList: FabricDetails[] = res.data;
        setFabricsList(fabricDetailsList);
        setLoading(false);
      })
      .catch((err) => console.log("here", err));
  }, [productId]);

  const addToCartClick = useCallback(() => {
    dispatch(cartActions.addToCart({ productId: selectedProductId, stockId: selectedFabricDetails?.stock_id, qty }));
    message.success({ 
      content: "Added product to cart",
      style: {
        marginTop: '90px'
      }  
    });
    // window.woopra.track("product_details:add_to_cart:click", {
    //   url: window.location.pathname,
    //   title: document.title,
    //   productId,
    //   productLabel: productDetail?.details?.label,
    // });
  }, [dispatch, selectedProductId, selectedFabricDetails, qty]);

  const fabricSelectionChange = useCallback(
    (e: RadioChangeEvent) => {
      setSelectedProductId(e.target.value);
      setQty(1);
    },
    []
  );

  const onBuyNowClick = React.useCallback(() => {
    // const COUNTRY_CODE = "+91";
    // const PHONE_NO = "9078813658";
    // const REDIRECT_URL =
    //   window.location.hostname === "localhost"
    //     ? "http://localhost:3000/checkout"
    //     : "https://thestupefiant.com/checkout";
    if (!itemExistsInCart) {
      dispatch(cartActions.addToCart({ productId: selectedProductId, stockId: selectedFabricDetails?.stock_id, qty }));
      // window.open(
      //   `https://www.phone.email/auth/sign-in?countrycode=${COUNTRY_CODE}&phone_no=${PHONE_NO}&redirect_url=${REDIRECT_URL}`,
      //   "peLoginWindow",
      //   "toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0, width=500, height=560, top=" +
      //     (window.screen.height - 600) / 2 +
      //     ", left=" +
      //     (window.screen.width - 500) / 2
      // );
    }
    // } else {
    //   window.open(
    //     `https://www.phone.email/auth/sign-in?countrycode=${COUNTRY_CODE}&phone_no=${PHONE_NO}&redirect_url=${REDIRECT_URL}`,
    //     "peLoginWindow",
    //     "toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0, width=500, height=560, top=" +
    //       (window.screen.height - 600) / 2 +
    //       ", left=" +
    //       (window.screen.width - 500) / 2
    //   );
    // }
    navigate("/checkout");
  }, [dispatch, itemExistsInCart, selectedProductId, selectedFabricDetails, navigate, qty]);

  const reduceQty = useCallback(
    () => {
      setQty((q) => q - 1);
    },
    []
  );

  const increaseQty = useCallback(
    () => {
      setQty((q) => q + 1);
    },
    []
  );

  return (
    <div style={{ paddingBottom: 50 }}>
      {loading && <PageLoader />}
      {!loading && (
        <Row>
          <Col sm={24} md={24} lg={12} className="width-100-percent">
            <div className="product-details-gallery">
              <div className="cover-image">
                <Image src={selectFabricImages?.[0]?.image_url} alt="" />
              </div>
              <div className="stack">
                {selectFabricImages
                  ?.filter((_, idx) => idx > 0)
                  ?.map((image: FabricImage, idx: number) => (
                      <Image src={image?.image_url} alt="" />
                ))}
              </div>
            </div>
            <ReactCarousel className="product-details-image-container">
              {selectFabricImages
                ?.map((image: FabricImage) => (
                  <div key={image?.id}>
                    <img src={image?.image_url} alt="" />
                  </div>
                ))}
            </ReactCarousel>
          </Col>
          <Col sm={24} md={24} lg={12} className="width-100-percent">
            <div className="product-info-container-div">
              <p className="product-label"><strong>{selectedFabricDetails?.label}</strong></p>
              <div className="product-details">
                <div><strong>Code:</strong> {selectedFabricDetails?.fabric_code}</div>
                <div><strong>Fabric:</strong> {selectedFabricDetails?.fabric}</div>
                <div><strong>Width:</strong> {selectedFabricDetails?.width}</div>
                <div><strong>Usage:</strong> {selectedFabricDetails?.item_usage}</div>
                <div><strong>Work:</strong> {selectedFabricDetails?.work}</div>
              </div>
              <br/>
              <p><strong className="medium-font"><s className="red-text"><span className="black-text">₹{Number(selectedFabricDetails?.price ?? 0) + 300}</span></s> ₹{selectedFabricDetails?.price} per metre</strong></p>
              <div>
                <div className="select-color-heading-container">
                  <strong className="medium-font">Fabric options</strong>
                  {/* <SizeChartModal /> */}
                </div>
                {!isFabricOutOfStock && (
                  <div className="fabric-radio-select">
                    <Radio.Group
                      style={{ marginTop: 5 }}
                      size="small"
                      buttonStyle="solid"
                      value={selectedProductId}
                      onChange={fabricSelectionChange}
                    >
                      <Space size={"middle"}>
                        {fabricsList?.map(fabric => (
                          <Radio.Button key={fabric?.id} style={{ height: 50, width: 50 }} disabled={fabric?.qty < 1} value={fabric?.id}>
                            <img style={{ height: "100%", width: "100%", position: "absolute", top: 0, left: 0 }} src={fabric?.image_url} alt="" />
                          </Radio.Button>
                        ))}
                      </Space>
                    </Radio.Group>
                  </div>
                )}
              </div>
              <br/>
              {!isFabricOutOfStock && <div className="quantity-update">
                <p>
                  <strong className="medium-font">Quantity (in metre)</strong>
                </p>
                <Space>
                  {qty > 0 && (
                    <Tooltip title="Remove">
                      <Button
                        size="small"
                        type="default"
                        shape="circle"
                        icon={<MinusOutlined />}
                        disabled={qty === 1}
                        onClick={reduceQty}
                      />
                    </Tooltip>
                  )}
                  <span className="medium-font">{qty}</span>
                  <Tooltip title="Add">
                    <Button
                      size="small"
                      type="default"
                      shape="circle"
                      icon={<PlusOutlined />}
                      disabled={qty === maxQty}
                      onClick={increaseQty}
                    />
                  </Tooltip>
                </Space>
              </div>}
              <div className="product-details-btn-container">
                {isFabricOutOfStock && <Tag color="error">OUT OF STOCK</Tag>}
                {!isFabricOutOfStock && (
                  <>
                    <Button
                      className="medium-font"
                      disabled={selectedProductId < 1}
                      type="primary"
                      shape="round"
                      onClick={addToCartClick}
                      size="large"
                    >
                      <ShoppingOutlined />
                      ADD TO CART
                    </Button>
                    <Button size="large" shape="round" onClick={onBuyNowClick} className="buy-now-btn medium-font">
                      BUY NOW
                    </Button>
                  </>
                )}
              </div>
              <br />
              <Collapse
                bordered={false}
                defaultActiveKey={[]}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="site-collapse-custom-collapse medium-font"
              >
                <Collapse.Panel header="Description" key="1" className="site-collapse-custom-panel">
                  <p>{selectedFabricDetails?.description}</p>
                </Collapse.Panel>
                <Collapse.Panel header="Washing instruction" key="5" className="site-collapse-custom-panel">
                  <p>{selectedFabricDetails?.washing_instruction}</p>
                </Collapse.Panel>
                <Collapse.Panel header="Shipping policy" key="6" className="site-collapse-custom-panel">
                  <ul>
                    <li>We ship across India. For international deliveries, please contact us.</li>
                    <li>Order are shipped within 24-48 hours of being confirmed.</li>
                    <li>All orders are shipped by Blue dart. Shipment tracking details will be mailed to you on the registered email ID.</li>
                    <li>It takes apprixmately 3-7  bussiness days  to deliver your product. Some times delivery may take longer due to bad weather, flight/ transportation delays, political disruptions and / or other unforeseen circumstances.</li>
                  </ul>
                </Collapse.Panel>
                <Collapse.Panel header="Return policy" key="7" className="site-collapse-custom-panel">
                  <p>
                    Our fabric are put through a rigorous 30- step quality check throughout the entire production process. 
                    From the lining of the threads to the beading of the embroidery every thing is looked into with a keen eye and cleared for packing and shipping. 
                    Due to these strict measures, our production quality is unparalleled. Owing to the nature of the trade, once a fabric has been cut out and delivered it cannot be returned to us as it's been cut per your individual requirement and order quantity, which varies from order to order.
                  </p>
                </Collapse.Panel>
                <Collapse.Panel header="Disclaimer" key="8" className="site-collapse-custom-panel">
                  <p>
                    Product specifications are a close aprroximate in value. Expect a slight variation in color.
                    There will always be a minor variation in the color you see on your computer screen/phone display and the one that is received on the fabric since the technologies involved for display and printing are different. 
                    A customer must place an order keeping this slight color variation in mind. Please note- on returns shall be accepted on the basis of color and description discrepancy.
                  </p>
                </Collapse.Panel>
              </Collapse>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ProductDetails;
