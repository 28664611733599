import {
  // CloseOutlined,
  InstagramFilled,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  // SearchOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { Button, Drawer, DrawerProps, Space } from "antd";
import { Header } from "antd/lib/layout/layout";
import React from "react";
import { useLocation } from "react-router-dom";
import { profile } from "../../utils/profileRelatedQueries";
import { UserProfile } from "../profile/Profile";
import AppLogo from "./AppLogo";
import AppMenuItems from "./AppMenuItems";
import CartIcon from "./CartIcon";

const MenuDrawer = () => {
  const { pathname } = useLocation();
  const [open, setOpen] = React.useState(false);
  const [placement] = React.useState<DrawerProps["placement"]>("left");
  const [profileDetails, setProfileDetails] = React.useState<UserProfile>();

  const getProfileDetails = React.useCallback(() => {
    profile()
      .then((res) => {
        const userProfile: UserProfile = res?.data;
        setProfileDetails(userProfile);
      })
      .catch((err) => console.log("here", err));
  }, []);

  const showDrawer = () => {
    setOpen(true);
    // window.woopra.track("menu_btn:click", {
    //   url: window.location.pathname,
    //   title: document.title,
    // });
  };

  const onClose = () => {
    setOpen(false);
    // window.woopra.track("menu:close", {
    //   url: window.location.pathname,
    //   title: document.title,
    // });
  };

  React.useEffect(() => {
    getProfileDetails();
  }, [getProfileDetails, pathname]);

  return (
    <div className="menu-drawer-container">
      <Button type="link" size="large" onClick={showDrawer}>
        {!open && <MenuUnfoldOutlined style={{ fontSize: 20, color: "white" }} />}
        {open &&  <MenuFoldOutlined style={{ fontSize: 20, color: "white" }} />}
      </Button>
      
      <Drawer
        placement={placement}
        width={500}
        onClose={onClose}
        visible={open}
        getContainer={false}
        className="menu-drawer"
        closeIcon={null}
      >
        <div className="app-menu-drawer">
          <AppMenuItems closeMenu={onClose} profileDetails={profileDetails} />
          <div className="drawer-footer-follow-us">
            <Space>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.instagram.com/e3_theofficial?igsh=MWN2d3ViMTNtYmk0Mw=="
              >
                <InstagramFilled style={{ fontSize: 20 }} />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://wa.me/message/MKQLIRKHVUS2O1"
              >
                <WhatsAppOutlined style={{ fontSize: 20, marginBottom: 6 }} />
              </a>
            </Space>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

const AppHeader = () => {
  
  return (
    <Header className="app-header">
      <div className="app-header-container">
        <MenuDrawer />
        <AppLogo />
        <div className="cart-icon-container">
          <CartIcon />
        </div>
      </div>
    </Header>
  );
};

export default React.memo(AppHeader);
