
import React from "react";
import BestSellers from "./BestSellers";
import SearchBar from "./SearchBar";
import Wholesaler from "./Wholesaler";
import { Divider } from "antd";
// import { AppState } from "../../redux-utils/store";

// interface FeaturedImage {
//   id: number;
//   image_url: string;
//   label: string;
//   description: string;
//   product_id: number;
// }

// const COVER_IMAGES = [
//   "https://thestupefiant.com/images/cover/collections.jpg",
// ];

const Home = () => {
  return (
    <>
      {/* <video className="intro-video-square" autoPlay loop>
        <source src="https://thestupefiant.com/videos/intro_square.mp4" type="video/mp4" />
      </video>
      <video className="intro-video-landscape" autoPlay loop>
        <source src="https://thestupefiant.com/videos/intro_landscape.mp4" type="video/mp4" />
      </video> */}
      <SearchBar />
      <BestSellers />
      <Divider /> 
      <Wholesaler />
    </>
  );
};

export default React.memo(Home);
