import { ArrowRightOutlined, DeleteOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, message, Result, Row, Space, Tag, Tooltip } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { cartActions } from "../../redux-utils/reducers/cartReducer";
import { AppState } from "../../redux-utils/store";
import { getCartDetails } from "../../utils/cartPageQueries";
import PageLoader from "../Loaders/PageLoader";

export interface CartProduct {
  id: number; // stock id
  product_id: number;
  label: string;
  price: number;
  color_id: number;
  qty: number;
  color_name: string;
  hex: string;
  image_url: string;
}

const CartPage = () => {
  const addedProductsInfo = useSelector((state: AppState) => state.cart?.items);
  const [cartProducts, setCartProducts] = useState<CartProduct[]>([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showOutOfStock, setShowOutOfStock] = useState(false);
  const outOfStockIds = React.useMemo(() => {
    const stockIds: number[] = [];
    cartProducts.forEach(prod => {
      const cartQty = addedProductsInfo?.find(info => info?.stockId === prod.id)?.qty ?? 0;
      cartQty > prod?.qty && stockIds.push(prod?.id);
    });
    return stockIds;
  }, [cartProducts, addedProductsInfo]);

  useEffect(() => {
    const addedStockIds = addedProductsInfo?.map((p) => p?.stockId)?.join(",");
    if (addedStockIds?.length > 0) {
      getCartDetails(addedStockIds)
      .then((res) => {
        const cartProductsResp: CartProduct[] = res.data;
        const outOfStock = !!cartProductsResp?.find(prod => {
          const cartQty = addedProductsInfo?.find(info => info?.stockId === prod.id)?.qty ?? 0;
          return cartQty > prod?.qty;
        });
        setCartProducts(cartProductsResp ?? []);
        setLoading(false);
        if (outOfStock) {
          setShowOutOfStock(true);
        }
      })
      .catch((err) => console.log("here", err));
    } else {
      setLoading(false);
    }
  }, [addedProductsInfo]);

  const reduceQty = useCallback(
    (stockId: number) => {
      dispatch(cartActions.reduceQty({ stockId }));
      // window.woopra.track("cart_page:reduce_qty:click", {
      //   url: window.location.pathname,
      //   title: document.title,
      //   stockId,
      // });
    },
    [dispatch]
  );

  const increaseQty = useCallback(
    (stockId: number) => {
      dispatch(cartActions.increaseQty({ stockId }));
      // window.woopra.track("cart_page:increase_qty:click", {
      //   url: window.location.pathname,
      //   title: document.title,
      //   stockId,
      // });
    },
    [dispatch]
  );

  const removeCartProduct = useCallback(
    (stockId: number) => {
      setCartProducts((products) =>
        products?.filter(
          (product) =>
            !(product?.id === stockId)
        )
      );
      dispatch(cartActions.removeFromCart({ stockId }));
      // window.woopra.track("cart_page:remove_item:click", {
      //   url: window.location.pathname,
      //   title: document.title,
      //   stockId,
      // });
    },
    [dispatch]
  );
  
  const subTotal = cartProducts?.reduce((total, product) => {
    const qty =
      addedProductsInfo?.find(
        (p) => p?.stockId === product?.id
      )?.qty ?? 0;
    return total + product?.price * qty;
  }, 0);
  const shippingCharges = 90;
  const totalCharges = subTotal < 3000 ? subTotal + shippingCharges : subTotal;

  const checkoutClick = React.useCallback(() => {
    const addedStockIds = addedProductsInfo?.map((p) => p?.stockId)?.join(",");
    if (addedStockIds?.length > 0) {
      setLoading(true);
      getCartDetails(addedStockIds)
      .then((res) => {
        const cartProductsResp: CartProduct[] = res.data;
        const outOfStock = !!cartProductsResp?.find(prod => {
          const cartQty = addedProductsInfo?.find(info => info?.stockId === prod.id)?.qty ?? 0;
          return cartQty > prod?.qty;
        });
        setCartProducts(cartProductsResp ?? []);
        setLoading(false);
        if (!outOfStock) {
          navigate("/checkout");
        } else {
          message.warning({ 
            content: "Some items in your cart is out of stock", 
            style: {
              marginTop: "60px"
            }
          });
          setShowOutOfStock(true);
        }
      })
      .catch((err) => { 
        console.log("here", err);
        setLoading(false); 
      });
    }
  }, [navigate, addedProductsInfo]);

  return (
    <>
      {loading && <PageLoader />}
      {!loading && cartProducts?.length === 0 && (
        <Result
          title={"Bag is empty!"}
          extra={
            <Link to="/">
              <Button type="primary">HOME</Button>
            </Link>
          }
        />
      )}
      {!loading && cartProducts?.length > 0 && (
        <>
          <h1 className="cart-heading">Cart</h1>
          <Row>
            <Col xs={24} lg={16} style={{ padding: 5 }}>
              <table className="cart-page-table-small">
                <tbody>
                  {cartProducts?.map((product) => {
                    const qty =
                      addedProductsInfo?.find(
                        (p) => p?.stockId === product?.id
                      )?.qty ?? 0;
                    const total = product?.price * qty;
                    return (
                      <tr>
                        <td style={{ width: 130 }}>
                          <img
                            className="cart-page-product-image"
                            alt=""
                            src={product?.image_url}
                          />
                        </td>
                        <td>
                          <div className="product-details">
                            <div className="detail">
                              <Link
                                className="product-label-link"
                                to={`/shop/${product?.product_id}`}
                              >
                                {(product?.label || "").length > 37 ? `${(product?.label || "").substring(0, 37)}...` : (product?.label || "")}
                              </Link>
                              <div>Price: <s className="red-text"><span className="black-text">₹{Number(product?.price ?? 0) + 300}</span></s> ₹{product?.price}</div>
                              <div>Total: ₹{total}</div>
                              <div>
                                <Space>
                                  {qty === 1 && (
                                    <Tooltip title="Remove from cart">
                                      <Button
                                        size="small"
                                        type="default"
                                        shape="circle"
                                        icon={<DeleteOutlined />}
                                        onClick={removeCartProduct.bind(
                                          null,
                                          product?.id
                                        )}
                                      />
                                    </Tooltip>
                                  )}
                                  {qty > 1 && (
                                    <Tooltip title="Remove">
                                      <Button
                                        size="small"
                                        type="default"
                                        shape="circle"
                                        icon={<MinusOutlined />}
                                        disabled={qty === 1}
                                        onClick={reduceQty.bind(
                                          null,
                                          product?.id
                                        )}
                                      />
                                    </Tooltip>
                                  )}
                                  <span>{qty}</span>
                                  <Tooltip title="Add">
                                    <Button
                                      size="small"
                                      type="default"
                                      shape="circle"
                                      icon={<PlusOutlined />}
                                      disabled={qty >= product?.qty}
                                      onClick={increaseQty.bind(
                                        null,
                                        product?.id
                                      )}
                                    />
                                  </Tooltip>
                                  {showOutOfStock && outOfStockIds.includes(product.id) && <Tag color="warning">{product.qty} Available</Tag>}
                                </Space>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>
            <Col xs={24} lg={8} style={{ padding: 5 }}>
              <Card bordered={true} className="medium-font">
                <div className="left-right">
                  <div className="left gray-text">Subtotal</div>
                  <div className="right gray-text">₹{subTotal}</div>
                </div>
                {subTotal < 3000 && (
                  <div className="left-right">
                    <div className="left gray-text">Shipping charges</div>
                    <div className="right gray-text">₹{shippingCharges}</div>
                  </div>
                )}
                <div className="left-right">
                  <div className="left"><strong>Total</strong></div>
                  <div className="right"><strong>₹{totalCharges}</strong></div>
                </div>
                <Divider />
                <div className="place-order">
                  <Button size="large" shape="round" onClick={checkoutClick} type="primary" className="medium-font">
                    Place order <ArrowRightOutlined />
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default React.memo(CartPage);
