import { Menu } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserProfile } from "../profile/Profile";
import { getFabricCategories } from "../../utils/menuItemQueries";
import PageLoader from "../Loaders/PageLoader";

interface AppMenuItemsProps {
  closeMenu: () => void;
  profileDetails: UserProfile | undefined;
}

interface MenuCategory {
  id: number;
  category: string;
  sub_category: string;
}

const AppMenuItems: React.FC<AppMenuItemsProps> = ({ closeMenu }) => {
  const [categories, setCategories] = useState<MenuCategory[]>([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    setCategoriesLoading(true);
    getFabricCategories()
    .then(response => {
      const categories: MenuCategory[] = response.data;
      setCategories(categories);
      setCategoriesLoading(false);
    })
    .catch(error => {
      console.log("Error : ", error);
      setCategoriesLoading(false);
    })
  }, []);

  const onCategoryClick = useCallback((entry: MenuCategory) => {
    closeMenu();
    navigate(`/shop?category_id=${entry.id}`);
  }, [navigate, closeMenu]);

  const categoriesMap = new Map<string, MenuCategory[]>();
  categories?.forEach(entry => {
    categoriesMap.set(entry.category, [...(categoriesMap.get(entry.category) ?? []), entry]);
  });

  return (
    <>
      {categoriesLoading && <PageLoader />}
      {!categoriesLoading && (
        <Menu mode="inline" className="medium-font">
          {Array.from(categoriesMap.keys()).map(category => (
            <Menu.SubMenu key={category} title={category}>
              {categoriesMap.get(category)?.map(entry => (
                <Menu.Item key={category + entry.sub_category} onClick={onCategoryClick.bind(null, entry)} className="app-menu-sub-items small-font">{entry.sub_category}</Menu.Item>
              ))}
            </Menu.SubMenu>
          ))}
        </Menu>
      )}
    </>
  );
};

export default React.memo(AppMenuItems);
