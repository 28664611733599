import Axios from "axios";

export const getProductsForListingPage = (category_id?: number, search?: string) => {
  const searchParams = new URLSearchParams();
  category_id && searchParams.append("category_id", `${category_id}`);
  search && searchParams.append("search", `${search}`);
  return Axios.get(
    `https://e3fabrica.com/backend/get_products_for_listing_page.php?${searchParams.toString()}`
  );
};

export const getFabricStockDetails = (product_id: number) => {
  return Axios.get(
    `https://e3fabrica.com/backend/get_fabric_stock_details.php?product_id=${product_id}`
  );
};
