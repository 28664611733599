import * as React from "react";

const BagIcon = () => (
    <svg width={18} height={18} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 653.3 761.6">
        <title>E3 bag icon</title><polygon points="50.1 230 5.44 756.6 647.84 756.6 601.25 230.82 50.1 230" className="bag-icon-class1"/>
        <path d="M204.91,225.62" transform="translate(-73.36 -17.2)" className="bag-icon-class2"/>
        <path d="M207.32,246.28a203,203,0,0,1-1.31-23C206,114.44,293.13,26.2,400.59,26.2s194.58,88.24,194.58,197.08a202.84,202.84,0,0,1-1.54,24.92" transform="translate(-73.36 -17.2)" className="bag-icon-class2"/>
        <path d="M207.32,246.28c11.25-98,93.49-174.08,193.27-174.08s182,76.07,193.26,174.08" transform="translate(-73.36 -17.2)" className="bag-icon-class2"/>
    </svg>
);

export default React.memo(BagIcon);