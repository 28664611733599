import Axios from "axios";

export const getFabricsListImages = (id: number) => {
  return Axios.get(
    `https://e3fabrica.com/backend/get_fabrics_list_images.php?product_id=${id}`
  );
};

export const getFabricsList = (id: number) => {
  return Axios.get(
    `https://e3fabrica.com/backend/get_fabrics_list.php?product_id=${id}`
  );
};
