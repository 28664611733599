import { Button } from "antd";
import * as React from "react";
import { useNavigate } from "react-router-dom";

const Wholesaler = () => {
    const navigate = useNavigate();
    return (
        <div className="wholesale-box">
            <div className="image">
                <img src="https://e3fabrica.com/images/wholesale.jpg" alt="" />
            </div>
            <div className="content">
                <h1 className="extra-large-font">Wholesale</h1>
                <p className="medium-font">For Wholesale quantities E3 supplies high quality affordable fabrics to manufacturer designers and retailer. Offering a wide range materials, including natural fibres, syntehtics and prints.</p>
                <Button onClick={() => navigate("/wholesale")} size="large" type="primary" shape="round">Order</Button>
            </div>
        </div>
    );
};

export default React.memo(Wholesaler);