import * as React from "react";
import Marquee from "react-fast-marquee";
import { INSTAGRAM_LINK } from "../../utils/links";

const Banner = () => {
    // React.useEffect(() => {
    //     let current = 0;
    //     const width = window.innerWidth;
    //     const banner = document.getElementById("app-banner");
    //     setInterval(() => {
    //         if (banner) {
    //             current -= 5;

    //             banner.style.left = current + "px";
    //         }
    //     }, 60);
    // }, []);

    return (
        <div id="app-banner" className="app-banner">
            <Marquee className="marquee-container">
                <h2>Free shipping for orders above ₹3000</h2>
                <h2>.</h2>
                <a target="_blank" rel="noreferrer" href={INSTAGRAM_LINK}><h2>Follow us on Instagram to get latest updates. Click here</h2></a>
            </Marquee>
        </div>
    );
}

export default Banner;