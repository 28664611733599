import { createSlice } from "@reduxjs/toolkit";

interface ProductInfo {
  productId: number;
  stockId: number;
  qty: number;
}

export interface CartState {
  items: ProductInfo[];
}

const initialState: CartState = {
  items: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const idx = state.items.findIndex(
        (item) => 
          item?.stockId === action?.payload?.stockId
      );
      if (idx >= 0) {
        state.items[idx].qty = action?.payload?.qty;
      } else {
        state.items.push({
          productId: action?.payload?.productId,
          stockId: action?.payload?.stockId,
          qty: action?.payload?.qty,
        });
      }
    },
    reduceQty: (state, action) => {
      const idx = state.items.findIndex(
        (item) =>
          item?.stockId === action?.payload?.stockId
      );
      idx >= 0 && state.items[idx].qty--;
    },
    increaseQty: (state, action) => {
      const idx = state.items.findIndex(
        (item) =>
          item?.stockId === action?.payload?.stockId
      );
      idx >= 0 && state.items[idx].qty++;
    },
    removeFromCart: (state, action) => {
      state.items = state.items.filter(
        (item) =>
          !(
            item?.stockId === action?.payload?.stockId
          )
      );
    },
    clearCart: (state) => {
      state.items = [];
    },
  },
});

export const cartActions = cartSlice.actions;
export default cartSlice.reducer;
