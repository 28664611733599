import { Typography } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";
import * as React from "react";

const PrivacyPolicy = () => {
  return (
    <Typography className="medium-font">
      <Title level={3}>Privacy Policy</Title>
      <Paragraph>
        E3Fabrica ("we," "our" or "us") is committed to protecting your
        privacy. The Privacy Policy explains how your personal information is
        collected, used, and disclosed by E3Fabrica.
      </Paragraph>
      <Paragraph>
        This Privacy Policy applies to our website www.e3fabrica.com, and to
        associated subdomains (collectively, our "Service") By accessing or
        using our Service, you signify that you have read, understood, and agree
        to our collection, storage, use, and disclosure of your personal
        information and described in this Privacy Policy and our Terms of
        Service.
      </Paragraph>
      <Title level={3}>Definitions and key terms</Title>
      <Paragraph>For this Privacy Policy:</Paragraph>
      <Paragraph>
        Cookie: small amount of data generated by a website and saved by your
        web browser it is used to identify your browser, provide analytics,
        remember information about you such as your language preference or login
        information.
        <br />
      </Paragraph>
      <Paragraph>
        Company: when this policy mentions "Company" "we" "us" or "our" refers
        to E3Fabrica Enterprise House no-7 Plot No-516/1729, Shanti Vihar, Kiit Square, Patia, Bhubaneswar, Odisha, 751024 that is
        responsible for your information under this Privacy Policy. Country:
        where E3Fabrica or the owners/founders of E3Fabrica are based, in this
        case in india. Customer: refers to the company, organization or person
        that signs up to use E3Fabrica service to manage relationship with your
        customer or service users. Device: any internet connected device such as
        a phone, tablets, computer or any other device that can be used to visit
        E3Fabrica and use the services. IP address: Every device connected to
        the Internet in assigned a number known as an internet protocol (IP)
        address. These numbers are usually assigned in geographic blocks. An IP
        address can often be used to identity the location from which a device
        is connecting to the Internet.
        <br />
      </Paragraph>
      <Paragraph>
        Personnel: refers to those individuals who are employed by E3Fabrica or
        are under contract to perform a service on behalf of one of the parties.
        Personal Data: any information that directly, indirectly, or in
        connection with other information including a personal Identification
        number allows for the identification or identifiability of a natural
        person. Service: refers to the service provided by E3Fabrica as
        described in the relative terms (if available) and on this platform.
        Third-party service: refers to advertisers contest sponsors. promotional
        and marketing partners, and others who provide our content or whose
        products or services we think may interest you. Website: E3Fabrica's
        site, which can be accessed via this URL www.e3fabrica.com You: a person
        or entity that is registered with E3Fabrica to use the Services.
      </Paragraph>
      <Title level={3}>What Information Do We Collect?</Title>
      <Paragraph>
        We collect information from you when you visit our service, register,
        place an order subscribe to our newsletter respond to a survey or fill
        out a form.
        <ul>
          <li>Name/username</li>
          <li>Phone Number</li>
          <li>Email address</li>
          <li>Mailing/Billing address</li>
        </ul>
      </Paragraph>
      <Title level={3}>How Do We Use The Information We Collect?</Title>
      <Paragraph>
        Any of the information we collect from you may be used in one of the
        following ways:
        <ul>
          <li>
            To personalize your experience (your information helps us to better
            respond to your individual needs)
          </li>
          <li>
            To improve our service (we continually stive to improve our service
            offerings based on the information and feedback we receive from you!
            To improve customer service (your information helps us to more
            effectively respond to your customer service requests and support
            needs)
          </li>
          <li>To process transactions</li>
          <li>
            To administer a contest, promotion, survey or other site feature
          </li>
          <li>To send periodic email</li>
        </ul>
      </Paragraph>
      <Title level={3}>
        When do we use customer information from third parties?
      </Title>
      <Paragraph>
        We receive some information from the third parties when you contact us
        For example, when you submit your email address to us to show interest
        in becoming our customer, we receive information from a third party that
        provides automated fraud detection services to us. We also occasionally
        collect formation that in made publicly available on social media
        websites. You can control how much of your information social media
        websites make public by visiting these websites and changing your
        privacy settings.
      </Paragraph>
      <Title level={3}>
        Do we share the information we collect with third parties?
      </Title>
      <Paragraph>
        We may share the information that we collect, both personal and non-
        personal with third parties such as advertisers contest sponsors,
        promotional and marketing partners, and others who provide our content
        or whose products or services we think may interest you. We may also
        share it with our current and future affiliated companies and business
        partners, and if we are involved in a merger asset sale or other
        business reorganization, we may also share or transfer your personal and
        non- personal information to our successors-in-interest.
      </Paragraph>
      <Paragraph>
        We may engage trusted third party service providers to perform functions
        and provide services to us, such as hosting and maintaining our servers
        and our service, database storage and management, email management
        storage marketing, credit card processing customer service and fulfiling
        orders for products and services you may purchase through our platform
        We will likely share your personal information, and possibly same non-
        personal information, with these third parties to enable them to perform
        these services for us and for you.
      </Paragraph>
      <Paragraph>
        We may share portions of our log file data, including IP addresses, for
        analytics purposes with third parties such as web analyses partners
        application developers and ad networks. If your IP address is shared,
        may be used to estimate general location and other technographics such
        as connection speed, whether you have visited the service in a shared
        location and type of device used to visit the service. They may
        aggregate information about our advertising and what you see on the
        service and then provide auditing, research and reporting for us and our
        advertisers
      </Paragraph>
      <Paragraph>
        We may also disclose personal and non-personal information about you to
        government or law enforcement officials or private parties as well, in
        our sole discretion, believe necessary or appropriate in order to
        respond to claims, legal process(including subpoenas), to protect our
        rights and interests or those of a third party, the safety of the public
        or any person to prevent or stop any illegal, unethical, or legally
        actionable activity, or to otherwise comply with applicable court
        orders, laws, rules and regulations.
      </Paragraph>
      <Title level={3}>
        Where and when is information collected from customers and end users?
      </Title>
      <Paragraph>
        We will collect personal information that you submit to us we may also
        receive personal information about you from third parties us described
        above.
      </Paragraph>
      <Title level={3}>How Do We Use Your Email Address?</Title>
      <Paragraph>
        By submitting your email address on this website, you agree to receive
        emails from us. You can cancel your participation in any of these email
        lists at any time by clicking on the opt out link or other unsubscribe
        option that is included in the respective email we only send emails to
        people who have authorized us to contact them, either directly or
        through a third party, we do not send unsolicited commercial emails,
        because we hate spam as much as you do. By submitting your email
        address, you also agree to allow us to use your email address for
        customer audience targeting on sites like Facebook,Instagram, where we
        display custom advertising to specific people who have opted in to
        receive communications from us. Email addresses submitted only through
        the order processing page will be used for the sole purpose of sending
        you information and updates pertaining to your order. If, however, you
        have provided the same email to us through another method we may use it
        for any of the purposes stated in this Policy Note II at any time you
        would like to unsubscribe from receiving future emails, we include
        detailed unsubscribe instructions at the bottom of each email.
      </Paragraph>
      <Title level={3}>
        Could my information be transferred to other countries?
      </Title>
      <Paragraph>
        We are incorporated in India. Information collected in our website.
        through direct interactions with you, or from use of our help services
        may be transfered from time to time to our offices or personnel, or to
        third parties, located throughout the world, and may be viewed and
        hosted anywhere in the world, including countries that may not have laws
        of general applicability regulating the use and transfer of such data.
        To the fullest extent allowed by applicable to, by using any of the
        above, you voluntarily consent to the trans border transfer and hosting
        of such information.
      </Paragraph>
      <Title level={3}>
        Is the information collected through our service secure?
      </Title>
      <Paragraph>
        We take precautions to protect the security of your information. We have
        physical, electronic, and managerial procedures to help safeguard
        prevent unauthorized access, maintain data security and correctly use
        your information. However, neither people nor security system are
        foolproof, including encryption systems, in addition, people can committ
        intentional crimes, make mistakes or fall to follow policies. Therefore
        while we use reasonable efforts to protect your personal information, we
        cannot guarantee is absolute security. If applicable law imposes any non
        disclaimable duty to protect your personal information, you agree that
        intentional misconduct will be the standards used to measure our
        compliance with that duty.
      </Paragraph>
      <Title level={3}>Can I update or correct my information?</Title>
      <Paragraph>
        The rights you have to request updates or corrections to the information
        we collect depend on your relationship with us. Personnel may update or
        correct their information us detailed in our internal company employment
        policies Customers have the right to request the restriction of certain
        uses and disclosures of personally identifiable information as follows
        You can contact us in order (1) update or contact your personally
        Identifiable information, (2) change your preferences with respect to
        communications and other information you receive from us, or (3) delete
        the personally identifiable information maintained about you on our
        system (subject to the following paragraph), by cancelling your account
        Such updates, corrections, changes and deletions will have no effect on
        other information that we maintain, or information that we have provided
        to third parties in accordance with this Privacy Policy prior to such
        update correction, change or deletion. To protect your privacy and
        security, we may take reasonable steps (such as requesting a unique
        password) to verify your identity before granting you profile access or
        making corrections You are responsible for maintaining the secrecy of
        your of your unique password and account information at all times. You
        should be aware that it is not technologically possible to remove each
        and every record of the information you have provided to us from our
        system. The need to back up our systems to protect information from
        inadvertent loss means that a copy of your information may cost in a non
        erasable form that will be difficult or impossible for us to locate,
        Promptly after receding your request, all personal information stored
        databases we actively Use and other readily searchable media will be
        updated corrected, changed or deleted, as appropriate as soon as and to
        the extent reasonably and technically practicable, if you are an end
        user and wish to update, delete, or receive any information we have
        about you, you may do so by contacting the organization of which you are
        a customer.
      </Paragraph>
      <Title level={3}>Personnel</Title>
      <Paragraph>
        If you are one of our workers or applicants, we collect information you
        voluntarily provide to us. We use the information collected for Human
        Resources purposes in order to administer benefits to workers and screen
        applicants. You may contact us in order to (1) update or correct your
        information (2) change your preferences with respect to communications
        and other information you see from us, or (3) receive a record of the
        information we have relating to you Such updates, corrections, changes
        and deletions will have no effect on other information that we maintain,
        or Information that we hare provided to third parties in accordance with
        this Privacy Policy price to such update, correction, change or
        deletion.
      </Paragraph>
      <Title level={3}>Sale of Business</Title>
      <Paragraph>
        We reserve the right to transfer information to third party in the event
        of sale merger or other transfer of all or substantially all of the
        assets of us or any of its Corporate Affilates (as defined here), or
        that portion of us or any of its Corporate Afliattes to which the
        Service relates, or in the event that we discontinue our business or
        file a petition or have filed against us a petition in bankruptcy,
        reorganization or similar proceeding provided that the third party
        agrees to adhere to the terms of this Privacy Policy.
      </Paragraph>
      <Title level={3}>Affiliates</Title>
      <Paragraph>
        We may disclose information (including personal information about you to
        our Corporate Affiliates. For purposes of this Privacy Policy,
        "Corporate Afilliate" means any person or entity which directly or
        indirectly controls, is controlled by or is under common control with
        us, whether by ownership or otherwise. Any information relating to you
        that we provide to our Corporate Affiliate will be treated by those
        Corporate affiliate in accordance with the terms of this Privacy Policy.
      </Paragraph>
      <Title level={3}>How Long Do We Keep Your Information?</Title>
      <Paragraph>
        We keep your information only so long as we need to provide service to
        you and fulfill the purposes described in this policy This also the case
        for anyone that we share your information with and who cames out
        services on our behalf. When we no longer need to use your information
        and there is no need for us to keep it to comply with our legal or
        regulatory obligations, we'll either remove it from our systems or
        depersonalize it so that we can't identify you.
      </Paragraph>
      <Title level={3}>How Do We Protect Your Information ?</Title>
      <Paragraph>
        We impliment a variety of security measures maintain the safety of your
        personal information when you place an order or enter, submit, or access
        your personal information We offer the use of a secure server. All
        supplied sensitive/credit information is transmitted via Secure Socket
        Layer(SSL) technology and then encrypted into our Payment gateway
        providers database only to be accessible by those authorized with
        special access rights to such systems, and are required to keep the
        information confidential After a transaction your private information
        (credit cards social security numbers, financial, etc.) in never kept
        one. We cannot however, around or warrant the absolute security of any
        information you transmit to us or guarantee that your information on the
        Service may not be accessed disclosed, altered, or destroyed by a breach
        of any of our physical, technical, or managerial safeguards.
      </Paragraph>
      <Title level={3}>Governing Law</Title>
      <Paragraph>
        The laws of india, excluding its conflicts of law rules, shall govern
        this Agreement and your use of our service. Your use of our service may
        also be subject to other local, state, national, or international laws.
      </Paragraph>
      <Title level={3}>Your Consent</Title>
      <Paragraph>
        By using our service, registering an account, or making a purchase, you
        consent to this Privacy Policy.
      </Paragraph>
      <Title level={3}>Links to Other Websites</Title>
      <Paragraph>
        This Privacy Policy applies only to the Services The Services may
        contain links to other websites not operated or controlled by We are not
        responsible for the content accuracy or opinions expressed in such
        websites, and such websites are not investigated, monitored or checked
        for accuracy or completeness by us. Please remember that when you use a
        link to go from the services to another website, our Privacy Policy is
        longer in effect Your browsing and interaction on any other website,
        including those that have a link on our platform, is subject to thet
        website's own rules and policies. Such third partirs may use their own
        cookies or other methods to collect information about you.
      </Paragraph>
      <Title level={3}>Cookies</Title>
      <Paragraph>
        We use cookies to identify the areas of our website that you have
        warned. A Cookie a small piece of data stored on your computer or mobile
        device by your web browser. We use Cookies to personalize the Content
        that you see on our website. Most web browsers can be set to disable the
        use of Cookies. However, if you disable Cookies, you may not be able to
        access functionality on our website correctly or at all we never place
        Personally identifiable information in Cookies.
      </Paragraph>
      <Title level={3}>Remarketing Services</Title>
      <Paragraph>
        We use remarketing services. What is Remarketing in digital
        marketing,(remarketing targeting) is the practice of serving ads across
        the inter people who have already visited your website. It allows your
        company to seem like they're "following people around the internet by
        serving ads on the websites and platforms they use most.
      </Paragraph>
      <Title level={3}>Payment Details</Title>
      <Paragraph>
        In respect to any credit card/Debit Card or other payment processing
        details you have provided us we commit that this confidential
        information will be stored in the most secure manner possible.
      </Paragraph>
      <Title level={3}>Changes To Our Privacy Policy</Title>
      <Paragraph>
        If we decide to change our privacy policy, we will past those changes on
        this page, and/or update the Privacy Policy modification below
      </Paragraph>
      <Title level={3}>Third-Party Services</Title>
      <Paragraph>
        We may display, include or make avalable third-party content (including
        data, information, applications and other products services) or provide
        links to third party websites or services (Third Party Services")
      </Paragraph>
      <Paragraph>
        You acknowledge and agree that we shall not be responsible for any Third
        Party Services, including their accuracy, compile timeliness, validity,
        copyright compliance, legality, decency, quality or any other aspect
        thereof. We do not assume and shall not have any liability or
        responsibility to you of any other person or entity for any Third-Party
        Services.
      </Paragraph>
      <Paragraph>
        Third-Party Services and links thereto are provided solely as a
        convenience to you and you access and use them entirely at your own risk
        and subject to such third parties' terms and condition.
      </Paragraph>
      <Title level={3}>Analytics Tools</Title>
      <Paragraph>
        Analytics tool allows you to measure the effectiveness of your
        advertising by understanding the actions people take on your website.
        You can use the analytics tool to Make sure your ads are shown to the
        right people. Analytics tool may collect information from your device
        when you use the service analytics tool collects information that is
        held in accordance with its Privacy Policy.
      </Paragraph>
    </Typography>
  );
};

export default PrivacyPolicy;
