import { Card, Col, Row } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBestSellers } from "../../utils/homePageQueries";
import { Product } from "../Shop/Shop";
import AddToCart from "../Shop/AddToCart";

const BestSellers = () => {
  const navigate = useNavigate();
  const [bestsellers, setBestsellers] = useState<Product[]>([]);

  useEffect(() => {
    getBestSellers()
      .then((res) => {
        const productArr = res.data as Product[];
        setBestsellers(productArr);
      })
      .catch((err) => console.log("here", err));
  }, []);

  const productCardClick = useCallback(
    (productId: number, productLabel: string) => {
      navigate(`/shop/${productId}`);
      // window.woopra.track("home:bestsellers:click", {
      //   url: window.location.pathname,
      //   title: document.title,
      //   productId,
      //   productLabel,
      // });
    },
    [navigate]
  );

  const categories = Array.from(new Set(bestsellers.map(p => p?.category))).filter(p => !!p);

  return (
    <>
      {(categories || []).map(category => (
        <div className="bestsellers-container">
          <h1 className="heading">{category} Bestsellers</h1>
          <Row className="bestsellers">
            {(bestsellers || []).filter(p => p?.category === category).map((product) => (
              <Col
                key={product?.id}
                className="product-card-column"
                xs={12}
                sm={8}
                md={8}
                lg={6}
                xl={6}
              >
                <Card
                  className="product-card"
                  cover={
                    <img
                      onClick={productCardClick.bind(null, product?.id, product?.label)}
                      className="product-card-image"
                      src={product?.image_url}
                      alt=""
                    />
                  }
                >
                  <div className="product-info-container">
                    <span className="label">{product?.label}</span>
                  </div>
                  <div className="add-to-cart-container">
                    <strong className="price"><s className="red-text"><span className="black-text">₹{Number(product?.price ?? 0) + 300}</span></s> ₹{product?.price}</strong>
                    <AddToCart productId={product?.id} />
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      ))}
    </>
  );
};

export default BestSellers;
