import React from "react";

const FooterLogo = () => {
  return (
    <img
      src="https://e3fabrica.com/images/logos/e3_logo_1.png"
      alt=""
      className="footer-logo"
    />
  );
};

export default React.memo(FooterLogo);
