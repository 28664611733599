import Search from "antd/lib/input/Search";
import * as React from "react";
import { useNavigate } from "react-router-dom";

const SearchBar = () => {
    const navigate = useNavigate();

    const onSearch = React.useCallback((search) => {
        if (search) {
            navigate(`/shop?search=${search}`);
        }
    }, [navigate]);

    return (
        <Search className="medium-font" style={{ paddingLeft: 5 }} placeholder="Search for products" onSearch={onSearch} enterButton />
    );
};

export default React.memo(SearchBar);