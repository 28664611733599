import { Typography } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";

const AboutUs = () => {
  return (
    <Typography className="medium-font">
      <Title level={3}>About Us</Title>
      <Paragraph>Welcome to E3</Paragraph>
      <Paragraph>
        At E3, we believe that clothing is more than just fabric—it's a reflection of who you are. Founded in 2015, our mission is to provide stylish, high-quality apparel that makes every day an opportunity to express your individuality.
      </Paragraph>
      <Paragraph>
        Our journey began with a passion for fashion and a vision to create a store where everyone could find something they love. From the latest trends to timeless classics, our curated collections are designed to cater to diverse tastes and lifestyles. We source our fabrics from trusted suppliers, ensuring each piece not only looks great but feels amazing too.
      </Paragraph>
      <Paragraph>
        At the heart of our store is a commitment to exceptional customer service. Our friendly team is always here to help you find the perfect outfit or offer personalized styling advice. We take pride in creating a shopping experience that is as enjoyable and unique as the clothing we offer.
      </Paragraph>
      <Paragraph>
        Whether you're searching for the perfect dress for a special occasion or everyday essentials to refresh your wardrobe,  E3 is here to inspire and delight. Thank you for choosing us to be a part of your style journey. We can’t wait to help you discover your next favorite piece!
      </Paragraph>
      <Paragraph>
        Our fabric store is a haven for textile enthusiasts, offering a curated selection of high-quality fabrics and handloom fabrics . With over 9 years of experience in the industry, we're passionate about providing our customers with exceptional service and a personalized shopping experience. Whether you're a seasoned seamstress or just starting your crafting journey, we have something to suit your needs.
      </Paragraph>
      <Paragraph>
        <strong>Stay stylish,</strong><br />
        <strong>The E3 Team</strong>
      </Paragraph>
    </Typography>
  );
};

export default AboutUs;
