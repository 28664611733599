import { Card, Col, Row } from "antd";
import React, { useState } from "react";
import { getProductsForListingPage } from "../../utils/productsListingPageQueries";
import { useNavigate } from "react-router-dom";
import PageLoader from "../Loaders/PageLoader";
import AddToCart from "./AddToCart";

export interface Product {
  id: number;
  label: string;
  image_url: string;
  description?: string;
  price?: number;
  category?: string;
  sub_category?: string;
}

const Shop = () => {
  const navigate = useNavigate();
  const [products, setProducts] = React.useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const urlParams = new URLSearchParams(window.location.search);
  const category_id = urlParams.get("category_id");
  const search = urlParams.get("search");
  React.useEffect(() => {
    setLoading(true);
    getProductsForListingPage(category_id ? Number(category_id) : undefined, search ?? undefined)
      .then((res) => {
        const products: Product[] = res.data;
        setProducts(products);
        setLoading(false);
      })
      .catch((err) => { 
        console.log("here", err);
        setProducts([]);
        setLoading(false); 
      });
  }, [category_id, search]);

  const productCardClick = React.useCallback(
    (productId: number, productLabel: string) => {
      navigate(`/shop/${productId}`);
      // window.woopra.track("shop:product_card:click", {
      //   url: window.location.pathname,
      //   title: document.title,
      //   productId,
      //   productLabel,
      // });
    },
    [navigate]
  );

  return (
    <>
      {loading && <PageLoader />}
      {!loading && products.length === 0 && (
         <div className="product-list-heading">
          <strong>No products available</strong>
        </div>
      )}
      {!loading && products.length > 0 && (
        <>
          {category_id && !search && <div className="product-list-heading">
            <strong>{products[0].category} / {products[0].sub_category}</strong>
            <span>{products.length} products</span>
          </div>}
          {search && !category_id && <div className="product-list-heading">
            <strong>Search results for "{search}"</strong>
          </div>}
          <Row>
            {products?.map((product) => (
              <Col
                key={product?.id}
                className="product-card-column"
                xs={12}
                sm={8}
                lg={6}
                xl={4}
              >
                <Card
                  className="product-card"
                  cover={
                    <img
                      onClick={productCardClick.bind(
                        null,
                        product?.id,
                        product?.label
                      )}
                      className="product-card-image"
                      src={product?.image_url}
                      alt=""
                    />
                  }
                >
                  <div className="product-info-container">
                    <span className="label">{product?.label}</span>
                  </div>
                  <div className="add-to-cart-container">
                    <strong className="price"><s className="red-text"><span className="black-text">₹{Number(product?.price ?? 0) + 300}</span></s> ₹{product?.price}</strong>
                    <AddToCart productId={product?.id} />
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      )}
    </>
  );
};

export default Shop;
